import { createRouter, createWebHistory } from "vue-router";
import store from "../store/store";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

function loadView(view) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `@/page/${view}.vue`);
}

const publicPath = process.env.BASE_URL;
const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: loadView("PageAppDefault"),
    children: [
      {
        path: "",
        components: {
          helper: loadView("PageHome"),
        },
        meta: { name: "welcome" },
      },
    ],
  },
  // if you omit the last `*`, the `/` character in params will be encoded when resolving or pushing
  {
    path: "/:pathMatch(.*)",
    name: "bad-not-found",
    component: loadView("PageAppDefault"),
    children: [
      {
        path: "",
        components: {
          helper: loadView("PageHome"),
        },
        meta: { name: "welcome" },
      },
    ],
  },
  //HOME
  {
    path: publicPath,
    component: loadView("PageAppDefault"),
    children: [
      {
        path: "",
        components: {
          helper: loadView("PageHome"),
        },
        meta: { name: "welcome" },
      },
    ],
  },
  
  // DATENSCHUTZ
  {
    path: publicPath + "datenschutz",
    component: loadView("PageAppDefault"),
    children: [
      {
        path: "",
        components: {
          helper: loadView("PageDatenschutz"),
        },
        meta: { name: "datenschutz" },
      },
    ],
  },
  // IMPRESSUM
  {
    path: publicPath + "impressum",
    component: loadView("PageAppDefault"),
    children: [
      {
        path: "",
        components: {
          helper: loadView("PageImpressum"),
        },
        meta: { name: "impressum" },
      },
    ],
  },
];

const router = createRouter({
  base: process.env.publicPath,
  history: createWebHistory(),
  routes, // short for `routes: routes`

  scrollBehavior(to, from, savedPosition) {
    // savedPosition

    // always scroll to top
    if (savedPosition) {
      return savedPosition;
    } else {
      if (to !== from) {
        return { top: 0 };
      }
    }
  },
});

// router.beforeEach((to, from, next) => {
//   if (checkPath(to, from)) {
//     if (to.meta.middleware) {
//       // const middleware = require(`@/middleware/${to.meta.middleware}`);
//       const middleware = require(`@/middleware/auth.js`);
//       if (middleware) {
//         store.commit("setLastNext", to);
//         middleware.default(next, store);
//       } else {
//         console.error("NEXT");
//         next();
//       }
//     } else {
//       console.error("NEXT");
//       next();
//     }
//   } else {
//     return false;
//   }
// });
router.beforeEach(() => {
  window.scrollTo(0, 0);
});

router.afterEach((to) => {
  window.scrollTo(0, 0);
  if (to.meta.name) {
    store.commit("setActiveRouteName", to.meta.name);
  } else {
    store.commit("setActiveRouteName", "welcome");
  }
  store.commit("setActiveRoute", to.href);
});

router.resolve({
  name: "bad-not-found",
  params: { pathMatch: "not/found" },
}).href; // '/not%2Ffound'
router.resolve({
  name: "not-found",
  params: { pathMatch: ["not", "found"] },
}).href; // '/not/found'

export default router;
